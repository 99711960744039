<template>
  <transition :name="placement !== 'page' ? 'top-pop' : null">
    <div v-if="ready" class="w-full lg:relative">
      <div v-if="showTimeSwitch && isLoading" class="animate-fade-in absolute inset-0 bg-slate-900/70 z-20 flex items-center justify-center">
        <Spinner/>
      </div>
      <header
        v-if="scheduleData && showTitle"
        class="flex justify-between items-center py-2 capitalize font-bold border-b-[1px] border-b-slate-400"
        :class="size !== 'lg' ? 'px-2' : '!border-0 !pt-0'"
      >
        <template v-if="showTimeSwitch && timeTabs.length > 1">
          <div class="flex items-center">
            <div class="text-sm">Races</div>
            <div class="flex leading-none rounded-lg ml-2 text-2xs">
              <div
                v-for="tab in timeTabs"
                :key="`lb-tab-${tab.scheduleName}`"
                class="py-1.5 px-1.5 sm:px-2 bg-slate-700 first:rounded-l-lg last:rounded-r-lg cursor-pointer"
                :class="{ '!bg-blue-500': scheduleData.scheduleName === tab.scheduleName }"
                @click="changeLeaderboard(tab.scheduleName)"
              >
                {{ tab.label }}
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="!hideName">
          {{ leaderboardData?.name || scheduleData.title }}
          <slot name="header"/>
        </div>

        <div class="flex items-center">
          <LeaderboardCountdown
            v-if="showLeaderBoardCountdown"
            :key="scheduleData?.endTime"
            :end-time="scheduleData?.endTime"
            class="mr-0.5"
            :class="size === 'lg' ? 'text-sm !px-2 !py-0.5' : null"
            @ended="scheduleEnded"
          />

          <button
            v-if="!scheduleData?.finalised"
            title="Leaderboard information"
            class="icon-ico-info rounded-full px-1.5 leading-none shrink-0 lg:h-full transition-colors bg-slate-500 hover:bg-slate-200"
            :class="size === 'lg' ? 'text-xl' : 'text-sm sm:text-lg'"
            @click="openInfoModal()"
          />
        </div>
        <DropdownMain
            v-if="size === 'lg'"
            :options="countList"
            :active-option="activeCountOption"
            theme="input-small"
            @select="setCount"
          >
          {{ selectedCount }}
        </DropdownMain>
      </header>
      <div ref="container" class="relative">
        <section
          v-if="(activeMode.id === 'position' && !isPlayerInEntries) || !entries.length"
          class="flex items-center justify-center animate-fade-in"
          :style="`min-height: ${containerMinHeight}px`"
        >
          <p class="text-white text-center text-sm mt-2">
            {{ !entries.length ? noResultsText : `You haven't entered the leaderboard yet` }}
          </p>
        </section>

        <section
          v-else-if="entries.length > 0"
          class="animate-fade-in relative"
        >
          <div
            class="flex px-3 text-2xs font-semibold"
            :class="size === 'lg' ? 'sm:text-sm border-b-[1px] border-b-slate-500 bg-slate-900 py-3 rounded-t-lg' : 'sm:text-xs text-green-500 py-1'"
          >
            <div class="w-1/12">
              <span class="hidden sm:inline-block">Rank</span>
            </div>
            <div class="w-4/12">Player</div>
            <div v-if="showPrizesCol" class="w-5/12">Prize</div>
            <div :class="{'flex-1': !showPrizesCol}" class="w-2/12 text-right">{{ pointsHeader }}</div>
          </div>
          <transition-group name="list-position">
            <LeaderboardEntry
              v-for="(item, index) in entries"
              :key="item.displayName"
              :item="item"
              :display-type="pointDisplayType"
              :show-prize="!!showPrizesCol"
              :decimal-places="pointDisplayDecimals"
              :rounding-decimals="pointRoundingDecimals"
              :metadata="metadata"
              :placement="placement"
              :size="size"
              :class="(size === 'lg' && index % 2 !== 0) ? 'bg-slate-900/55' : 'bg-slate-900/25'"
            />
          </transition-group>
        </section>

        <button
          v-if="!hideCloseButton"
          class="absolute -bottom-[16px] left-1/2 -ml-3 z-10 p-1 rounded-b-full"
          @click.prevent="emits('close')"
        >
          <div class="flex justify-center rounded-b-full h-3 w-6 bg-slate-800">
            <span class="icon-ico-arrow-bold-up text-[9px] block"/>
          </div>
        </button>
        <template v-if="!showTitle">
          <div class="absolute -bottom-[25px] right-0 z-20 lg:bottom-auto lg:-top-7 lg:right-0 flex">
            <LeaderboardCountdown
              v-if="showLeaderBoardCountdown"
              :end-time="scheduleData.endTime"
              class="mr-0.5 h-[23px]"
              @ended="scheduleEnded"
            />
            <button
              v-if="!scheduleData?.finalised"
              title="Leaderboard information"
              class="text-sm rounded-full flex h-[23px] w-[23px] transition-colors bg-slate-500 hover:bg-slate-200"
              @click.prevent.stop="openInfoModal()"
            >
              <span class="icon-ico-info m-auto"/>
            </button>
          </div>
          <div v-if="showTimeSwitch && timeTabs.length > 1" class="flex items-center absolute z-20 left-0 -bottom-[27px] lg:bottom-auto lg:-top-7">
            <div class="flex leading-none rounded-lg ml-0.5 text-2xs border border-slate-300 overflow-hidden">
              <div
                v-for="tab in timeTabs"
                :key="`lb-timeTab-${tab.scheduleName}`"
                class="py-1.5 px-2 bg-slate-700 cursor-pointer"
                :class="{ '!bg-blue-500': scheduleData.scheduleName === tab.scheduleName }"
                @click="changeLeaderboard(tab.scheduleName)"
              >
                {{ tab.label }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useUiStore } from '@/store/ui';
import { Log } from '~/core/logger';

const props = defineProps({
  pollingBlock: {
    type: Boolean,
    default: false,
  },
  triggerUpdate: {
    type: Boolean,
    default: false,
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  leaderboardScheduleName: {
    type: String,
    default: null,
  },
  leaderboardScheduleNameOverride: {
    type: String,
    default: null,
  },
  placement: {
    type: String,
    default: null,
  },
  showTitle: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  hideName: {
    type: Boolean,
    default: false,
  },
  showTimeSwitch: {
    type: Boolean,
    default: false,
  },
  useLast: {
    type: Boolean,
    default: false,
  },
  tournament: {
    type: Object,
    default: null,
  },
  noResultsText: {
    type: String,
    default: 'No players yet... why not be the first one!',
  },
  type: {
    type: String,
    default: 'monthlyreelrace',
  },
});

const emits = defineEmits([
  'leaderboardLoaded',
  'close',
]);

const { $api, $rollbar, $dayjs } = useNuxtApp();

const uiStore = useUiStore();
const { openInfoCmsModal, } = uiStore;

const leaderboardId = ref(null);
const leaderboardData = ref(null);
const scheduleData = ref(null);
const ready = ref(false);
const isPlayerInEntries = ref(true);
const poller = ref(null);
const take = ref(3);
const skip = ref(0);
const aroundCount = ref(1);
const selectedCount = ref(props.count || 3);
const containerMinHeight = ref(0);
const scheduleTimeout = ref(null);
const currentLeaderboardScheduleName = ref(props.leaderboardScheduleName);
const leaderboardOverrideActive = ref(!!props.leaderboardScheduleNameOverride);
const isLoading = ref(true);
const endTime = ref();
const modes = ref([
  {
    name: 'Leaderboard',
    id: 'ranking',
    active: true,
  },
  {
    name: 'Your Position',
    id: 'position',
    active: false,
  },
]);
const countList = ref([
  {
    text: 10,
    value: 10,
  },
  {
    text: 20,
    value: 20,
  },
  {
    text: 30,
    value: 30,
  },
  {
    text: 40,
    value: 40,
  },
]);

const timeTabs = computed(() => {
  const tabs = [];

  const slotsDailyTime = $dayjs('2024-09-10T05:00').valueOf(); // TODO: Remove later
  const reelRaceSchedule = Date.now() >= slotsDailyTime ? 'SlotsDaily' : 'DailyMultiplier'; // TODO: Remove later
  const reelRaceLabel = Date.now() >= slotsDailyTime ? 'Daily' : 'Daily Multiplier'; // TODO: Remove later

  if (props.type !== 'livereelrace') {
    tabs.push({ name: 'monthlyreelrace', label: '$1m Race', scheduleName: 'MonthlyWagering', });
    tabs.push({ name: 'reelrace', label: 'Daily', scheduleName: 'SlotsDaily', });
    // tabs.push({ name: 'weeklyreelrace', label: 'Weekly', scheduleName: 'SlotsWeekly', });
    return tabs;
  }

  tabs.push({ name: 'livereelrace', label: 'Daily', scheduleName: 'LiveDaily', });
  // tabs.push({ name: 'weeklylivereelrace', label: 'Weekly', scheduleName: 'LiveWeekly', });
  return tabs;
});

const metadata = computed(() => leaderboardData.value?.metadata);
const activeMode = computed(() => modes.value.find(e => e.active === true));
const prizes = computed(() => leaderboardData.value?.prizes || []);
const entries = computed(() => {
  if (!leaderboardData.value?.entries?.length) { return []; }
  const entriesLocal = leaderboardData.value.entries.map(e => ({
    ...e,
    prizes: [],
  }));

  // Include user entry if not in entriesLocal already
  if (leaderboardData.value.userEntry) {
    entriesLocal.push({
      ...leaderboardData.value.userEntry,
      isPlayer: true,
      outOfRange: true,
      prizes: [],
    });
  }

  prizes.value.forEach((prize) => {
    for (let rank = prize.startRank; rank <= prize.endRank; rank++) {
      const entryIdx = entriesLocal.findIndex(entry => entry.rank === rank);
      if (entryIdx > -1) {
        entriesLocal[entryIdx].prizes.push(prize);
      }
    }
  });
  return entriesLocal;
});
const showPrizesCol = computed(() => entries.value.filter(entry => entry.prizes.length > 0).length);
const activeCountOption = computed(() => countList.value.find(i => i.value === selectedCount.value));
const pointDisplayType = computed(() => leaderboardData.value?.pointDisplayType || 'Standard');
const pointDisplayDecimals = computed(() => leaderboardData.value?.pointDisplayDecimals || 0);
const pointRoundingDecimals = computed(() => metadata.value?.pointRoundingDecimals ?? pointDisplayDecimals.value);
const pointsHeader = computed(() => metadata.value?.pointsHeader || 'Score');
const showLeaderBoardCountdown = computed(() => !!entries.value.length && endTime.value);

await init();

async function init() {
  let leaderboardScheduleName = currentLeaderboardScheduleName.value || props.leaderboardScheduleName;

  if (props.leaderboardScheduleNameOverride && leaderboardOverrideActive.value) {
    leaderboardScheduleName = props.leaderboardScheduleNameOverride;
  }

  if (!props.showTimeSwitch) {
    emits('leaderboardLoaded', false, leaderboardOverrideActive.value);
  }

  if (leaderboardScheduleName) {
    try {
      const scheduleDataLocal = await fetchLeaderboardSchedule(leaderboardScheduleName);
      if (scheduleDataLocal) {
        leaderboardId.value = scheduleDataLocal?.leaderboardId;
        scheduleData.value = scheduleDataLocal;
        currentLeaderboardScheduleName.value = leaderboardScheduleName;
        endTime.value = !!scheduleDataLocal?.endTime;
      } else {
        isLoading.value = false;
        if (props.leaderboardScheduleNameOverride && leaderboardOverrideActive.value) {
          leaderboardOverrideActive.value = false;
          currentLeaderboardScheduleName.value = props.leaderboardScheduleName;
          await init();
          return;
        }
        emits('leaderboardLoaded', false, leaderboardOverrideActive.value);
        return;
      }
    } catch (err) {
      isLoading.value = false;
      emits('leaderboardLoaded', false, leaderboardOverrideActive.value);
      return;
    }
  } else if (props.tournament.leaderboardId) {
    leaderboardId.value = props.tournament.leaderboardId;
  }

  // Load leaderboard data
  if (leaderboardId.value) {
    await fetchLeaderboard();
    ready.value = true;

    if (!scheduleData.value?.finalised) {
      startPolling();
    }
  }
}

async function fetchLeaderboardSchedule(leaderboardScheduleName) {
  try {
    if (props.useLast) {
      const data = await $api(`/leaderboard/schedule/${leaderboardScheduleName}/latest/1`, { method: 'GET', params: { finalised: true, }, });
      return data[0];
    } else {
      const data = await $api(`/leaderboard/schedule/${leaderboardScheduleName}/item`, { method: 'GET', });
      return data;
    }
  } catch (err) {
    $rollbar.error('fetchLeaderboardSchedule::: method failed', err);
  }
}

async function fetchLeaderboard() {
  let url;

  if (activeMode.value.id === 'ranking') {
    url = `/leaderboard/${leaderboardId.value}?&skip=${skip.value}&take=${selectedCount.value || take.value}`;
  } else if (activeMode.value.id === 'position') {
    url = `/leaderboard/${leaderboardId.value}/around?count=${aroundCount.value}`;
  }

  try {
    const data = await $api(url, { method: 'GET', });
    // let data = await $fetch(`/data/minigame.json`, { method: 'GET'}) // Testing data
    leaderboardData.value = data;
    isLoading.value = false;
    return emits('leaderboardLoaded', true, leaderboardOverrideActive.value);
  } catch (err) {
    Log.error('method failed', err);
  }
  isLoading.value = false;
}

function startPolling() {
  if (!poller.value) {
    poller.value = setInterval(() => {
      if (!props.pollingBlock) {
        fetchLeaderboard();
      }
    }, 5000);
  }
}

async function changeLeaderboard(leaderboardScheduleName) {
  if (currentLeaderboardScheduleName.value !== leaderboardScheduleName) {
    currentLeaderboardScheduleName.value = leaderboardScheduleName;
    isLoading.value = true;
    destroyPoller();
    await init();
  }
}

function destroyPoller() {
  if (poller.value) {
    clearInterval(poller.value);
    poller.value = null;
  }
}

function openInfoModal() {
  if (currentLeaderboardScheduleName.value || props.leaderboardScheduleName) {
    openInfoCmsModal({ modalId: currentLeaderboardScheduleName.value || props.leaderboardScheduleName, });
  }
}

function scheduleEnded() {
  if (!props.useLast) {
    destroyPoller();
    scheduleTimeout.value = setTimeout(async() => {
      ready.value = false;
      await init();
    }, 15000);
  }
}

function setCount(option) {
  selectedCount.value = option.value;
  fetchLeaderboard();
  destroyPoller();
  startPolling();
}

watch(
  () => props.triggerUpdate,
  () => {
    fetchLeaderboard();
  }
);

watch(
  () => entries.value,
  (newVal) => {
    isPlayerInEntries.value = newVal.find(e => e.isPlayer) || false;
  }
);

watch(
  () => props.tournament,
  () => {
    init();
  }
);

onBeforeUnmount(() => {
  destroyPoller();
  if (scheduleTimeout.value) {
    clearTimeout(scheduleTimeout.value);
    scheduleTimeout.value = null;
  }
});
</script>
